import { useNavigate } from 'react-router-dom';
import PropTypes from 'prop-types';
import { useState, useRef, useEffect } from 'react';
import './Header.css';
import orgamiPicto from '../../components/assets/logo/orgamipicto.ico';
import nameMission from '../../components/assets/svg/NameMission.svg';
import logout from '../../components/assets/svg/logout.svg';
import task from '../../components/assets/svg/task.svg';
import infoPerso from '../../components/assets/svg/infoPerso.svg';
import placeName from '../../components/assets/svg/placeName.svg';
import { getFetch } from '../../components/fonctions/request';
import { isBenevoleData, Benevole } from '../../components/interfaces/tBenevoles';

interface setTokenApiProps {
  setTokenApi: (token: boolean | string) => void;
}

function Header({ setTokenApi }: setTokenApiProps) {
  const [benevole, setBenevole] = useState<Benevole>();
  const nav = useNavigate();
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const menuRef = useRef<HTMLDivElement>(null);
  const clickRef = useRef<HTMLDivElement>(null);

  const logoutFunc = () => {
    localStorage.removeItem('tokenApi');
    setTokenApi(false);
  }

  const navToNewPage = (url: string) => {
    setIsMenuOpen(!isMenuOpen);
    nav(url)
  }

  const openClose = () => {
    setIsMenuOpen(!isMenuOpen);
  };

  useEffect(() => {
    function handleClickOutside(event: MouseEvent) {
      if (menuRef.current && !menuRef.current.contains(event.target as Node) && clickRef.current && !clickRef.current.contains(event.target as Node)) {
        setIsMenuOpen(false);
      }
    }

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [menuRef]);

  useEffect(() => {
    getFetch('api/Benevole/CurrentBenevole').then((benevole) => {
      if (isBenevoleData(benevole)) {
        setBenevole(benevole)
      } else {
        throw new Error('La réponse du serveur n\'est pas conforme à isBenevoleData');
      }
    })
  }, [])


  if (!benevole) {
    return (
      <>Loading...</>
    )
  }

  return (
    <div className='containerHeaderElementFixed'>
      <div className="parent icon">
        <div className="element" onClick={() => nav("/")}>
          <img src={orgamiPicto} alt="Retour à la page d'accueil" />
          <h1>HERCULE</h1>
        </div>
      </div>
      <div className="parent">
        <div className={`menuBurger ${isMenuOpen && 'active'}`} ref={menuRef}>
          <ul>
            <li>
              <div onClick={() => navToNewPage("/")}>
                <img src={placeName} alt="" />
                <button>Accueil</button>
              </div>
            </li>
            <li>
              <div onClick={() => navToNewPage("/MesInformations")}>
                <img src={infoPerso} alt="" />
                <button>Mes informations</button>
              </div>
            </li>
            <li>
              <div onClick={() => navToNewPage("/MesAffectations")}>
                <img src={task} alt="" />
                <button>Mes affectations</button>
              </div>
            </li>
            <li>
              <div onClick={() => navToNewPage("/Missions")}>
                <img src={nameMission} alt="" />
                <button>Toutes les missions</button>
              </div>
            </li>
            {/* <li>Envoyés un message</li> */}
            <li className='deconnection'>
              <div className='' onClick={() => { logoutFunc() }}>
                <img src={logout} alt="" />
                <button>Déconnexion</button>
              </div>
            </li>
          </ul>
        </div>
        {/* MENU BURGER */}
        <div className='cash'>
        <div className="click" onClick={() => openClose()} ref={clickRef}>
          <p>Menu &gt;</p>
        </div>
        </div>
      </div>
    </div>
  );
}

Header.propTypes = {
  setTokenApi: PropTypes.func,
}

export default Header;
